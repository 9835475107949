<template>
    <div>
        <div class="position-relative">
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <!-- Logo & Description -->
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <img src="img/LOGO_PGA.png" class="img-fluid floating">     
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <p class="lead text-white">
                                    Pourquoi faire ?
                                    <br/>
                                    Tout simplement pour gagner du temps !
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Cartes présentations -->
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-6">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-building" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Pour les professionnels</h6>
                                    <p class="description mt-3">
                                        Que vous soyez artisan, indépendant, structure de petite taille ou même PME :
                                        Choisir de travailler avec une secrétaire indépendante, c’est opter pour la flexibilité, la simplicité et un budget maîtrisé
                                    </p>
                                    <base-button type="warning" class="mt-4" onclick="document.getElementById('pro').scrollIntoView();">
                                        En savoir plus
                                    </base-button>
                                </card>
                            </div>
                            <div class="col-lg-6">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fa fa-home" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Pour les particuliers</h6>
                                    <p class="description mt-3">
                                        Faites appel à votre assistante privée à domicile pour vous soulager dans votre quotidien !
                                    </p>
                                    <br/>
                                    <base-button type="primary" class="mt-4" onclick="document.getElementById('particulier').scrollIntoView();">
                                        En savoir plus
                                    </base-button>
                                </card>
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Professionnels -->
        <section class="section section-lg" id="pro">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                        <!-- TODO voir pour emplacer par une image -->
                        <img v-lazy="'https://i.picsum.photos/id/423/600/700.jpg?hmac=DdDhM9uihrzT_9RJgRaCvO_SkHj-_Zx0hRfIbqL_sbE'" class="img-fluid floating">
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <icon name="ni ni-building" class="mb-5" size="lg" type="warning" shadow
                                  rounded></icon>
                            <h3>Pour les professionnels</h3>
                            <p>
                                Vous êtes artisan, indépendant, structure de petite taille ou même PME :<br/><br/>
                                Choisir de travailler avec une secrétaire indépendante, c’est opter pour la flexibilité, la simplicité et un budget maîtrisé pour :
                            </p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="warning" circle class="mr-3" icon="ni ni-watch-time"></badge>
                                        <h6 class="mb-0">Des missions de quelques heures par mois qui ne permettent pas l’embauche d’un salarié</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="warning" circle class="mr-3" icon="fa fa-stack-overflow"></badge>
                                        <h6 class="mb-0">Subvenir à une surcharge de travail temporaire</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="warning" circle class="mr-3" icon="fa fa-calendar"></badge>
                                        <h6 class="mb-0">Combler l’absence de votre secrétaire en arrêt ou en vacances</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="warning" circle class="mr-3" icon="ni ni-ruler-pencil"></badge>
                                        <h6 class="mb-0">Répondre à des besoins spécifiques que votre secrétaire n’est pas en mesure d’assurer</h6>
                                    </div>
                                </li>
                            </ul>
                            <p>
                                Votre secrétaire indépendante peut vous assister sur votre gestion administrative, commerciale et comptable 
                                <span style="cursor:pointer" @click="modals.modalComptable = true">
                                    <icon class="btn-tooltip text-info" v-b-tooltip.hover.bottom title="Plus d'informations" name="fa fa-info-circle"></icon>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal explication comptable -->
            <modal :show.sync="modals.modalComptable"
                gradient="danger"
                modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Votre attention est requise</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4"></h4>
                    <p>
                        Une secrétaire indépendante ne peut absolument pas exercer la comptabilité, ni même la saisie d’écritures, pour ses clients. 
                        L’exercice comptable est strictement réglementé par l’Ordre des Experts Comptables. 
                        L’exercice illégal de la profession d’expert-comptable, qui consiste à tenir la comptabilité de son client, 
                        de manière habituelle et non salariée, sans être inscrit au Tableau de l’Ordre, est un délit pénal puni de peines d’emprisonnement.
                        <br/>
                        Plus d’informations sur le site <b><a class="text-info" href="https://compta-illegal.fr" target="_blank">compta-illegal.fr</a></b>
                    </p>
                </div>
                <template slot="footer">
                    <base-button type="link"
                                text-color="white"
                                class="ml-auto"
                                @click="modals.modalComptable = false">
                        Fermer
                    </base-button>
                </template>
            </modal>
        </section>

        <!-- Particulier -->
        <section class="section bg-secondary" id="particulier">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <!-- TODO voir pour emplacer par une image -->
                        <img v-lazy="'https://i.picsum.photos/id/579/600/700.jpg?hmac=I9NHa27Ruw-CQn7vS3wMj1YmNyHeNgQc8RXCR8AkzFc'" class="img-fluid floating">
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <icon name="fa fa-home" class="mb-5" size="lg" type="primary" shadow rounded></icon>
                            <h3>Pour les particuliers</h3>
                            <p>
                                Vous êtes un particulier et vous avez besoin d'aide pour certaines démarches administratives :
                            </p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="primary" circle class="mr-3" icon="ni ni-laptop"></badge>
                                        <h6 class="mb-0">Vous n'êtes pas à l'aise avec les outils numériques ?</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="primary" circle class="mr-3" icon="fa fa-question-circle"></badge>
                                        <h6 class="mb-0">Vous avez besoin d'aide pour organiser vos papiers, pour constituer un dossier spécifique ?</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="primary" circle class="mr-3" icon="ni ni-time-alarm"></badge>
                                        <h6 class="mb-0">Vous souhaitez vous libérer de la corvée de "la paperasse" et vous dégager du temps libre pour vos proches ?</h6>
                                    </div>
                                </li>
                            </ul>
                            <p>
                                Faites appel à votre assistante privée à domicile pour vous soulager dans votre quotidien !
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Redirection -->
        <section class="section section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-1 bg-gradient-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container pt-lg pb-300">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-10">
                        <h2 class="display-3 text-white">Int&eacute;ress&eacute; pour travailler avec nous ?</h2>
                    </div>
                </div>
                <div class="row row-grid mt-5">
                    <div class="col-lg-4">
                        <icon name="fa fa-user" size="lg" gradient="white" shadow round color="primary"></icon>
                        <h5 class="text-white mt-3">Apprenez en plus sur moi</h5>
                        <p class="text-white mt-3">
                            Je m'appelle Pauline GATIN, jai {{age}} ans et je suis assistante indépendante !<br/>
                            <router-link to="/presentation">
                                <base-button type="primary">
                                    Qui suis-je ?
                                </base-button>
                            </router-link>
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <icon name="fa fa-briefcase" size="lg" gradient="white" shadow round color="primary"></icon>
                        <h5 class="text-white mt-3">D&eacute;couvrez nos prestations</h5>
                        <p class="text-white mt-3">
                            Nous étudions chaque demande au cas par cas pour répondre au mieux à vos besoins.<br/>  
                            <router-link to="/prestation">
                                <base-button type="primary">
                                    Nos prestations
                                </base-button>
                            </router-link>
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <icon name="fa fa-envelope" size="lg" gradient="white" shadow round color="primary"></icon>
                        <h5 class="text-white mt-3">N'h&eacute;sitez pas à nous contacter</h5>
                        <p class="text-white mt-3">
                            Vous pouvez nous contacter par email (<b><a class="text-info" href="mail:contact@pgassistante.fr">contact@pgassistante.fr</a></b>), 
                            par t&eacute;l&eacute;phone (<b><a class="text-info" href="tel:0748111186">07.48.11.11.86</a></b>), ou par le formulaire de contact.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact -->
        <formulaire-contact/>
    </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import FormulaireContact from "./components/FormulaireContact.vue";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
export default {
  name: "accueil",
  components: {
    Modal,
    FormulaireContact
  },
  directives: {
    BTooltip: VBTooltip
  },
  data() {
    return {
      modals: {
        modalComptable: false,
      },
      showSuccessAlert: false,
      showFailedAlert: false
    };
  },
  methods: {
      send: function () {
          const success = true;
          if (success) {
              this.showFailedAlert = false;
              this.showSuccessAlert = true;
          } else {
              this.showSuccessAlert = false;
              this.showFailedAlert = true;
          }
      }
  },
  computed: {
        age: function() {
            const today = new Date();
            const birthDate = new Date("04/04/1989");
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
    }
};
</script>

