import Vue from "vue";
import Router from "vue-router"
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Accueil from "./views/Accueil.vue";
import Presentation from "./views/Presentation.vue";
import Prestation from "./views/Prestation.vue";
import Contact from "./views/Contact.vue";
import Mention from "./views/Mention.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      alias: "/accueil",
      name: "accueil",
      components: {
        header: Header,
        default: Accueil,
        footer: Footer
      }
    },
    {
        path: "/presentation",
        name: "presentation",
        components: {
          header: Header,
          default: Presentation,
          footer: Footer
        }
    },
    {
        path: "/prestation",
        name: "prestation",
        components: {
          header: Header,
          default: Prestation,
          footer: Footer
        }
    },
    {
        path: "/contact",
        name: "contact",
        components: {
          header: Header,
          default: Contact,
          footer: Footer
        }
    },
    {
      path: "/mention",
      name: "mention",
      components: {
        header: Header,
        default: Mention,
        footer: Footer
      }
  }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
