<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-6">
                    <h3 class="text-primary font-weight-light mb-2">Merci de nous faire confiance !</h3>
                    <h4 class="mb-0 font-weight-light">Restons en contact</h4>
                </div>
                <div class="col-lg-6 text-lg-center btn-wrapper">
                    <!--a target="_blank" rel="noopener" href="#"
                       class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg">
                        <i class="fa fa-twitter"></i>
                    </a-->
                    <a target="_blank" rel="noopener" href="https://facebook.com/AssistanceGestionAdministrative/"
                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg">
                        <i class="fa fa-facebook-square"></i>
                    </a>
                    <!--a target="_blank" rel="noopener" href="#"
                       class="btn btn-neutral btn-icon-only btn-instagram btn-round btn-lg">
                        <i class="fa fa-instagram"></i>
                    </a-->
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-4">
                    <div class="copyright">
                        &copy; {{year}}
                        Pauline GATIN ⁢⁣‍⁢‌⁤⁣⁡‌⁢⁣⁢⁡‍‌⁡‍⁤‌⁢⁤⁣‌⁣⁣⁡‍⁢‍⁢⁢⁡⁢‍⁢‌⁤‌⁢⁤⁢⁢⁢⁢‌‌⁡‌‍‌⁢‌⁢‌‌‌‍‌‍‌⁤⁢⁢⁡⁢‍‌⁤‌⁣⁢⁢⁢⁡⁢⁡‌‌‌‍⁡‌‌⁡- 08220 CHAPPES
                    </div>
                </div>
                <div class="col-md-8">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/">
                                Accueil
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/presentation">
                                Qui suis-je ?
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/prestation">
                                Nos prestations
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/contact">
                                Contact
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/mention">
                                Mentions l&eacute;gales
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
