<template>
    <div>
        <div class="position-relative">
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <!-- Logo & Description -->
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <img src="img/LOGO_PGA.png" class="img-fluid floating">     
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <br/>
                                <h3 class="text-white">Mentions l&eacute;gales</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Mentions -->
        <section class="section section-lg" id="particulier">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-sm-12">
                        <icon name="fa fa-cogs" class="mb-5" size="lg" type="primary" shadow rounded></icon>
                        <h3>TODO</h3>
                    </div>
                </div>
                <div class="row row-grid">
                    <div class="col-sm-12">
                        <p>
                            TODO
                        </p>
                    </div> 
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: "mention",
  components: {}
};
</script>
