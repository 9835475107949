<template>
    <div>
        <div class="position-relative">
            <section class="section-shaped my-0">
                <div class="bg-gradient-success shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <!-- Logo & Description -->
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <img src="img/LOGO_PGA.png" class="img-fluid floating">     
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Profil -->
        <br/>
        <div class="profile-page">
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--300" no-body>
                    <div class="px-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="#">
                                        <img v-lazy="'img/profil.jpg'" class="rounded-circle">
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                                <div class="card-profile-actions py-4 mt-lg-0">
                                    <!-- A Gauche de l'image -->
                                    &nbsp;
                                </div>
                            </div>
                            <div class="col-lg-4 order-lg-1">
                                <div class="card-profile-stats d-flex justify-content-center">
                                    <!-- A Droite de l'image -->
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-5">
                            <h3>Pauline GATIN<span class="font-weight-light">, {{age}}</span></h3>
                            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>08220 CHAPPES</div>
                            <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>Secr&eacute;taire ind&eacute;pendante</div>
                            <div><i class="ni education_hat mr-2"></i>BTS Assistant(e) de Gestion PME-PMI</div>
                        </div>
                        <div class="mt-5 py-5 border-top text-center">
                            <div class="row justify-content-center">
                                <div class="col-lg-9">
                                    <p>
                                        Diplômée d’un BTS Assistant(e) de Gestion PME-PMI, j’ai exercé ma profession durant plus de 12 ans au sein de diverses entreprises dans le secteur privé en tant que secrétaire comptable, assistante administrative, assistante d’exploitation et assistante de direction.
                                        J’ai eu l’occasion de travailler dans différents domaines d’activité (environnement, recherche clinique, industrie, BTP, etc), me conférant ainsi une expérience professionnelle riche et variée, ce qui me permet d’avoir aujourd’hui une vision globale de mon métier et des besoins de mes clients.
                                        Polyvalente et réactive, je mets mes compétences au service d’artisans, indépendants, professions libérales, TPE/PME ou encore des particuliers.
                                        Capable d’adaptation et de flexibilité, je peux exercer mon activité en distanciel depuis mon domicile ou bien en présentiel dans les locaux ou au domicile de mes clients dans un rayon de 50 kms autour de Chappes (08220), commune proche de Rethel.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "presentation",
    components: {},
    computed: {
        age: function() {
            const today = new Date();
            const birthDate = new Date("04/04/1989");
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
    }
};
</script>

