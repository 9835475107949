<template>
    <header class="header-global">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <h1 class="title">PGA'ssistante</h1>
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                        <h1>PGA'ssistante</h1>
                    </router-link>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                <li class="nav-item">
                    <router-link slot="brand" class="nav-link nav-link-icon" to="/presentation">
                        <i class="fa fa-user"></i>
                        <span class="nav-link-inner--text">Qui suis-je ?</span>
                    </router-link>
                </li>
                <li>
                    <router-link slot="brand" class="nav-link nav-link-icon" to="/prestation">
                        <i class="fa fa-briefcase"></i>
                        <span class="nav-link-inner--text">Nos Prestations</span>
                    </router-link>
                </li>
                <li>
                    <router-link slot="brand" class="nav-link nav-link-icon" to="/contact">
                        <i class="fa fa-envelope"></i>
                        <span class="nav-link-inner--text">Contact</span>
                    </router-link>
                </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <!--li class="nav-item">
                    <a class="nav-link nav-link-icon btn-tooltip" href="#" target="_blank" rel="noopener" v-b-tooltip.hover.bottom title="Follow us on Twitter">
                        <i class="fa fa-twitter-square"></i>
                        <span class="nav-link-inner--text d-lg-none">Twitter</span>
                    </a>
                </li-->
                <li class="nav-item">
                    <a class="nav-link nav-link-icon btn-tooltip" href="https://facebook.com/AssistanceGestionAdministrative/" target="_blank" rel="noopener" v-b-tooltip.hover.bottom title="Like us on Facebook">
                        <i class="fa fa-facebook-square"></i>
                        <span class="nav-link-inner--text d-lg-none">Facebook</span>
                    </a>
                </li>
                <!--li class="nav-item">
                    <a class="nav-link nav-link-icon btn-tooltip" href="#" target="_blank" rel="noopener" v-b-tooltip.hover.bottom title="Follow us on Instagram">
                        <i class="fa fa-instagram"></i>
                        <span class="nav-link-inner--text d-lg-none">Instagram</span>
                    </a>
                </li-->
            </ul>
        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
export default {
  components: {
    BaseNav,
    CloseButton
  },
  directives: {
    BTooltip: VBTooltip
  },
};
</script>
<style>
.title {
    color: white;
}
</style>
